
import { defineComponent, PropType } from "vue";
import { State } from "@/types/order";

export default defineComponent({
  name: "PreOrderSteps",
  props: {
    list: {
      required: true,
      type: Object as PropType<{ [P: string]: State }>,
    },
  },
});
